<template>
  <PageCard
    pageName="FIN CRM"
    :iconClass="['fa', 'icon-screen-smartphone', 'fa-lg']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div
        :class="
          partnerSite ? ['homepage-content partner-site'] : ['homepage-content']
        "
      >
        <section class="  solution-section">
          <div class="">
            <div class="section container-margin" id="1">
              <FeatureDetailsCard
                title="FIN Query"
                description1="Deploy FIN Query to leverage the powerful combination of firmographics, technographics, performance and contact data for the most precise FI prospecting available anywhere."
                description2="In three easy steps, FIN Query (1) filters prospect institutions, (2) provides additional institution or contact data, and (3) instantly exports the data for laser-focused campaigns."
                button1Text="See Use Cases"
                :button1Link="usecaseLink"
                :galleryImages="FI_SOL_FIN_Q"
              />
            </div>
          </div>
        </section>
        <section class=" container-margin">
          <b-row class="about-crm-row">
            <b-col cols="6" class="px-1">
              <b-card class="about-border mb-0">
                <div slot="header">CRM Data Import License</div>
                <b-card-body class="p-0">
                  <div class="my-2">
                    CRM systems are only as good as the data housed within them.
                    Give your teams a competitive advantage by enriching your
                    CRM system with FI contact data, key technologies, financial
                    performance, and firmographic data.
                  </div>

                  <div class="about-footer">
                    <div class="text-center">
                      <a
                        href="https://www.fi-navigator.com/support/"
                        target="_blank"
                        class=" text-nowrap text-center cta"
                        >Request Demo</a
                      >
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col cols="6" class="px-1">
              <b-card class="about-border mb-0">
                <div slot="header">Single-Click Access from your CRM</div>
                <b-card-body class="p-0">
                  <div class="my-2">
                    Make meeting prep more efficient and valuable with fast
                    access to performance metrics, technographics, segmentation,
                    peer analytics, and more. By embedding FI Navigator’s
                    single- click access into your CRM Account record, users
                    will always have the most advanced FI analytics at their
                    fingertips.
                  </div>

                  <div class="about-footer">
                    <div class="text-center">
                      <a
                        href="https://www.fi-navigator.com/support/"
                        target="_blank"
                        class="text-nowrap text-center cta"
                        >Request Demo</a
                      >
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'

export default {
  name: 'AboutCRMdata',
  components: {
    PageCard,
    FeatureDetailsCard
  },
  data() {
    return {
      FI_SOL_FIN_Q: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q5.png'
      ]
    }
  },
  created() {
    document.title = 'FIN CRM'
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters('Authentication', ['isFiMenu']),
    usecaseLink() {
      return this.isFiMenu
        ? '/institution_analytics/about'
        : '/technographics/about'
    }
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.homepage-content {
  background-size: cover;
}
</style>
